import $ from 'jquery'
import { Section } from '../../common/section'
import { SectionManager } from '../../common/section_manager'
import './main.scss'

class InstagramSection extends Section {
  static initClass() {
    this.type = 'instagram'
  }

  onLoad() {
    const $photos = this.elem.find('[data-instagram-access-token]')
    let accessToken = $photos.attr('data-instagram-access-token')
    if (accessToken) {
      accessToken = $.trim(accessToken)
    }

    if (accessToken) {
      return instagramCache.get(accessToken, (photos) =>
        $photos.html(
          $.map(
            photos,
            (photo) =>
              `<a href="${
                photo.permalink || photo.link
              }" target="_blank"><img src="${
                photo.thumbnail_url ||
                photo.media_url ||
                __guard__(
                  photo.images != null
                    ? photo.images.low_resolution
                    : undefined,
                  (x) => x.url
                )
              }" /></a>`
          ).join('')
        )
      )
    }
  }
}
InstagramSection.initClass()

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined
}

$(function () {
  new SectionManager([InstagramSection])
})

// Caches fetched photos to speed up the editing preview and avoid multiple
// unnecessary requests to the Instagram API.
class InstagramCache {
  constructor() {
    this._cache = {}
    this._fetchCallbacks = {}
  }

  get(accessToken, callback) {
    let cached, fetchCallbacks
    if ((cached = this._cache[accessToken])) {
      return callback(cached)
    } else if ((fetchCallbacks = this._fetchCallbacks[accessToken])) {
      return fetchCallbacks.push(callback)
    } else {
      this._fetchCallbacks[accessToken] = [callback]
      return this._fetch(accessToken)
    }
  }

  _fetch(accessToken) {
    const url =
      accessToken.length > 70 // new style
        ? `https://graph.instagram.com/me/media?fields=id,media_type,media_url,thumbnail_url,permalink&access_token=${accessToken}&limit=12&callback=`
        : // old style
          `https://api.instagram.com/v1/users/self/media/recent?access_token=${accessToken}&count=6&callback=`
    return $.ajax({
      type: 'GET',
      dataType: 'jsonp',
      url,
      success: (response) => {
        if (response.data && response.data.length) {
          let photos = response.data.filter(
            (p) => p.media_type !== 'CAROUSEL_ALBUM'
          )
          photos = photos.slice(0, 6)

          this._cache[accessToken] = photos

          for (let callback of Array.from(this._fetchCallbacks[accessToken])) {
            callback(photos)
          }
        }

        return delete this._fetchCallbacks[accessToken]
      },
      error: (_response) => {
        return delete this._fetchCallbacks[accessToken]
      },
    })
  }
}

let instagramCache = new InstagramCache()
